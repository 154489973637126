<template>
  <div class="container indexContainer">
    <div class="slideWrapDiv">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item v-for="(item, index) in banners" class="slideWrap" :key="index">
          <img class="bannerImg" :src="item.image_file.obs_path" alt="" @click="godetail_slider(item)" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="main_index">
      <div class="floor" v-for="(item,index) in plateArticles" :key="item.id">
        <div class="titleWrap">
          <div class="title">
            <div class="black" :class="color(index)">{{item.name}}</div>
            <div class="more" v-if="index>0" @click="golist(item.id,item.name)">
              <span class="moreText">更多</span>
              <span class="iconfont icon-fanhui6"></span>
            </div>
          </div>
          <div class="itemBanner" v-if="item.shop_file!=null">
            <div class="left_img" style="width:100%">
              <img :src="item.shop_file!=null?item.shop_file.obs_path:''" alt="" @click="goDetail_banner(item)"/>
              <!-- <div class="checkoutBtn mobileShow" @click="goDetail('video',item.id)">
                立即观看
              </div> -->
            </div>
            <!-- <div class="right_content">
              <div class="intro">
                【新手必学】如何找到优质货源新手必学】如何找到优质货源新手必学】如何找到优质货源新手必学】如何找到优质货源
              </div>
              <div class="intro2">
                教你引流，打造一个赚钱得店铺
              </div>
              <div class="tags">
                <div class="tag">运营</div>
                <div class="tag">基础</div>
                <div class="tag">店铺流量</div>
              </div>
              <div class="checkoutBtn" @click="goDetail('video')">立即观看</div>
            </div> -->
            <div style="clear:both;"></div>
          </div>
        </div>
        <div class="listWrap">
          <div class="list" >
            <div class="item item_hover" @click="goDetail(articles_item.type,articles_item.id,item.id,item.name)" v-for="articles_item in item.article" :key="articles_item.id">
              <img class="caseImg" v-lazy="articles_item.image_file.obs_path" alt="" />
              <div class="caseInfo">
                <div class="intro">
                  {{articles_item.title}}
                </div>
                <div class="tags">
                  <div class="tag" v-for="tags_item in articles_item.tags" :key="tags_item.id" :style="{color:tags_item.color,borderColor:tags_item.color}">
                    {{tags_item.name?tags_item.name:''}}
                  </div>
                  <!-- <div class="tag">基础</div>
                  <div class="tag">店铺流量</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 楼层 -->
      <div class="floorBox" v-if="!mobile">
        <div
          class="floorNav"
          :class="{ active: currentIndex === index }"
          v-for="(item, index) in floorItem"
          :key="index"
          @click="floorChange(index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import '@/assets/css/global.css'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'Home',
  computed: {
    ...mapState(['mobile'])
  },
  data() {
    return {
      bannerHeight: '',
      banners: [],// 轮播图
      floorItem: [],// 左侧楼层列表
      len:0, // 由于后台数据库问题，计算展示文章用的
      currentIndex: 0,
      timer: null,
      token:'',
      plateArticles:[] // 首页的板块和文章
    }
  },
  mounted() {
    window.addEventListener('scroll', this.navScroll, true)
    let navBar = document.getElementsByClassName('navWrap')[0]
    navBar.style.display = 'block'
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.navScroll)
  },
  created(){
    this.getPlateMenus()
    this.getbanner()
  },
  methods: {
    ...mapMutations(['addNavList']),
    // 获取banner
    async getbanner(){
      let {data:res} = await this.$http.get('/slideshow');
      if(res.state == 200){
        this.banners = res.data
        console.log(res)
      }
    },
    // 获取板块列表
    async getPlateMenus(){
      let {data:res} = await this.$http.get('/get-plate-menus');
      if(res.state == 200){
        this.floorItem = res.data
        this.len = res.data.length;
        // console.log(res.data,'asfsdgs')
        this.addNavList(res.data)
        // 因为后台数据库问题 所以要先获取板块列表再进行首页板块以及文章的获取
         this.getPlateArticles()
      }
    },
    // 首页板块文章
    async getPlateArticles(){
      let {data:res} = await this.$http.get('/get-plate-articles',{params:{article_quantity:4}});
      if(res.state == 200){
        this.plateArticles = res.data
        console.log(this.plateArticles)
      }
    },
    floorChange(index) {
      this.currentIndex = index
      let floor = document.querySelectorAll('.floor')
      floor[index].scrollIntoView(false)
    },
    navScroll(e) {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      let floor = document.querySelectorAll('.floor')
      floor.forEach((item, index) => {
        if (scrollTop >= item.offsetTop - 200) {
          this.currentIndex = index
        }
      })
    },
    golist(plate_id,name) {
      this.$router.push(`/caselist?plate_id=${plate_id}&name=${name}`)
    },
    goDetail(type,id,plate_id,plate_name) {
      // 文章的跳转详情事件
      // type 类型--1是文章 2是视频 3是外部链接
      // id  文章/视频id
      // plate_id 板块id
      // plate_name 板块名称
      if (type == 1) {
        this.$router.push(`/details?detailsId=${id}&plate_id=${plate_id}&name=${plate_name}`)
      } else if(type == 2) {
        this.$router.push(`/video?detailsId=${id}&plate_id=${plate_id}&name=${plate_name}`)
      }else if(type ==3){
          window.open(id, '_blank').location;
      }
    },
    godetail_slider(item){
      // 轮播图跳转页面未修改完成
        if(item.link_type==3){
          // 链接类型是外部链接
          window.open(item.link, '_blank').location;
        }else if ((item.article_type==''||item.article_type==null) && item.link_type==2){
          // 链接类型是视频
          this.$router.push(`/videoCopy?src=${item.video_file.obs_path}&name=${item.name}`)
        }else if (item.article_type.type == 1 && item.link_type==1){
          // 链接类型是文章 但是文章的类型是文字
          this.$router.push(`/details?detailsId=${item.link}&plate_id=${item.id}&name=${item.name}`)
        }else if(item.article_type.type ==2 &&item.link_type==1){
        // 链接类型是文章 但是文章是视频类型
        this.$router.push(`/video?detailsId=${item.link}&plate_id=${item.id}`)
      }
    },
    goDetail_banner(item){
      // 板块banner的跳转详情事件
      if(item.link_form ==3){
        // 链接类型是外部链接
        window.open(item.plate_link, '_blank').location;
      }else if((item.article_type==''||item.article_type==null) && item.link_form==2){
        // 链接类型是视频
        this.$router.push(`/videoCopy?src=${item.video_file.obs_path}&name=${item.name}`)
      }else if (item.article_type.type == 1 && item.link_form==1) {
        // 链接类型是文章 但是文章的类型是文字
        this.$router.push(`/details?detailsId=${item.plate_link}&plate_id=${item.id}&name=${item.name}`)
      }else if(item.article_type.type ==2 &&item.link_form==1){
        // 链接类型是文章 但是文章是视频类型
        this.$router.push(`/video?detailsId=${item.plate_link}&plate_id=${item.id}`)
      }
    },
    color(index){
      return ''
      // if(index ==0){
      //   return ''
      // }else if(index==1){
      //   return 'red'
      // }else if((index+1)%2==1&&index!=1){
      //   return 'blue'
      // }else if((index+1)%3==1){
      //   return 'green'
      // }else if((index+1)%4==0){
      //   return 'origin'
      // }else{
      //   return ''
      // }
      // index==1?'red':index==2?'blue':index==3?'green':index==4?'origin':''
    }
  }
}
</script>
<style lang="less" scoped>
.el-carousel__container {
  height: 360px;
}
.indexContainer {
  .bannerImg {
    display: block;
    width: 100%;
    // min-width: 1189px;
    height: 100%;
  }
  .main_index {
    width: 1189px;
    margin: 0 auto;
    // background: cornflowerblue;
    position: relative;
    .titleWrap {
      width: 100%;
      padding: 22px 0;
      margin-top: 40px;
    }
    .title {
      width: 100%;
      height: 46px;
      font-family: SourceHanSansSC-Bold;
      color: #333333;
      div {
        font-size: 26px;
        font-weight: normal;
        letter-spacing: 0px;
      }
    }
    .itemBanner {
      width: 100%;
      max-height: 274px;
      background-color: #ffffff;
      border-radius: 5px;
      cursor: pointer;

      .left_img {
        width: 44%;
        max-height: 274px;
        float: left;
        .mobileShow {
          display: none;
        }
      }
      img {
        display: block;
        width: 100%;
        max-height: 274px;
      }
      .right_content {
        width: 50%;
        height: 100%;
        float: left;
        // padding-top: 65px;
        padding: 65px 32px 0 32px;
      }
      .caseInfo {
        width: 100%;
        // height: 105px;
        background: #fff;
        padding: 15px 10px 19px 10px;
      }
      .intro {
        width: 100%;
        height: 22px;
        // margin-top: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 10px;
        font-family: SourceHanSansCN-Regular;
        font-size: 18px;
        color: #333333;
      }
      .intro2 {
        height: 24px;
        font-family: SourceHanSansCN-Regular;
        font-size: 18px;
        letter-spacing: 0px;
        color: #999999;
        margin-bottom: 23px;
      }
      .checkoutBtn {
        width: 152px;
        height: 46px;
        background-color: #1f1f1f;
        border-radius: 23px;
        font-family: SourceHanSansCN-Regular;
        font-size: 18px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        float: right;
      }
      .checkoutBtn:hover {
        background: #3d3d3d;
      }
      .tags {
        width: 100%;
        min-height: 21px;
        display: flex;
        justify-content: start;
      }
      .tag {
        padding: 0px 4px;
        font-family: SourceHanSansCN-Regular;
        font-size: 16px;
        color: #ff9d4e;
        margin-right: 3px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fefaf1;
        border-radius: 3px;
        border: solid 1px #ffc662;
      }
    }
    .bgnone {
      background: none;
    }
    .black,
    .red,
    .blue,
    .green,
    .origin {
      width: 30%;
      float: left;
    }
    .red {
      color: #ff5039;
    }
    .blue {
      color: #2580fa;
    }
    .green {
      color: #13b69c;
    }
    .origin {
      color: #ff740e;
    }
    .more {
      float: right;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      .moreText {
        font-family: AlibabaPuHuiTiR;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 30px;
        letter-spacing: 0px;
        color: #666666;
      }
      .iconfont {
        font-size: 24px;
        color: #cccccc;
        margin-left: 6px;
      }
    }
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .bannerItem {
        width: 48%;
        height: 100%;
        img {
          width: 100%;
        }
      }
    }
    .listWrap {
      width: 100%;
      // height: 260px;
      // background-color: #ffffff;
      margin-top: 37px;
      .list {
        widows: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        .item {
          border-radius: 5px;
          height: 100%;
          width: 24%;
          overflow: hidden;
          cursor: pointer;
          margin-right: 15px;
          margin-bottom: 15px;
        }
        .item:nth-child(4n){
          margin-right: 0;
        }
        .caseImg {
          display: block;
          width: 100%;
          height: 155px;
        }
        .caseInfo {
          width: 100%;
          // height: 105px;
          background: #fff;
          padding: 15px 10px 19px 10px;
        }
        .intro {
          width: 100%;
          height: 40px;
          line-height: 20px;
          // margin-top: 17px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          margin-bottom: 10px;
          font-size: 16px;
          position: relative;
        }
        .tags {
        width: 100%;
        height: 21px;
        display: flex;
        justify-content: flex-start;
        overflow: hidden;
        align-items: center;
        flex-wrap: wrap;
      }
      .tag {
        min-width:25%;
        max-width: 30%;
        line-height: 19px;
        text-align: center;
        height: 100%;
        float: left;
        padding: 0px 4px;
        font-family: SourceHanSansCN-Regular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ff9d4e;
        border: 1px solid #ff9d4e;
        margin-right: 3px;
        box-sizing: border-box;
        border-radius: 5px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .tag:nth-child(4){
        margin-right: 0;
      }
      }
    }
  }
  // 楼层
  .floorBox {
    width: 101px;
    padding: 60px 0;
    background-color: #ffffff;
    border-radius: 50px;
    position: fixed;
    left: 120px;
    top: 60%;
    transform: translateY(-50%);
    z-index: 20;
    .floorNav {
      width: 100%;
      height: 52px;
      background-color: #ffffff;
      font-family: AlibabaPuHuiTiR;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 52px;
      text-align: center;
      letter-spacing: 0px;
      color: #666666;
      cursor: pointer;
    }
    .floorNav:hover {
      background-color: #fe3846;
      color: #fff;
      transition: 0.1s linear;
    }
    .active {
      background-color: #fe3846;
      color: #fff;
      transition: 0.1s linear;
    }
  }
  .backTop {
    width: 87px;
    height: 246px;
    background-color: #ffffff;
    border-radius: 43px;
    border: solid 1px #d2d2d2;
    position: fixed;
    right: 200px;
    bottom: 200px;
    z-index: 99;
  }
}

@media screen and (max-width: 1680px) {
  .indexContainer .floorBox {
    left: 20px;
  }
}
@media screen and (max-width: 1440px) {
  .indexContainer .floorBox {
    width: 0.7rem;
    padding: 0.4rem 0;
    background-color: #ffffff;
    border-radius: 0.5rem;
    position: fixed;
    left: 0.02rem;
    top: 60%;
    transform: translateY(-50%);
    z-index: 20;
    .floorNav {
      width: 100%;
      height: 0.4rem;
      background-color: #ffffff;
      font-family: AlibabaPuHuiTiR;
      font-size: 0.16rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 0.4rem;
      text-align: center;
      letter-spacing: 0rem;
      color: #666666;
      cursor: pointer;
    }
    .floorNav:hover {
      background-color: #fe3846;
      color: #fff;
      transition: 0.1s linear;
    }
    .active {
      background-color: #fe3846;
      color: #fff;
      transition: 0.1s linear;
    }
  }
}
@media screen and (max-width: 1339px) {
  .indexContainer .floorBox {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .indexContainer {
    .floorBox {
      display: none;
    }
    .slideWrapDiv {
      padding: 16px 0;
      background: #fff;
    }
    /deep/.el-carousel__container{
      height: auto!important;
      min-height: 180px;
    }
    // .slideWrap{
    //   height: auto!important;
    // }
    .bannerImg {
      display: block;
      width: 100%;
      // min-width: 1189px;
      // height: 100%;
    }
    .main_index {
      width: 100%;
      margin: 0 auto;
      // background: cornflowerblue;
      position: relative;
      min-height: 100%;
      .title {
        div {
          font-size: 0.3rem;
        }
      }
      // .itemBanner {
      //   width: 100%;
      //   // height: 274px;
      //   background-color: #ffffff;
      //   border-radius: 5px;
      //   img {
      //     display: block;
      //     width: 100%;
      //     height: 100%;
      //   }
      // }
      .itemBanner {
        width: 100%;
        // height: 274px;
        background-color: #ffffff;
        border-radius: 5px;
        cursor: pointer;

        .left_img {
          width: 100%;
          height: 100%;
          float: left;
          position: relative;
          .mobileShow {
            display: block;
            position: absolute;
            left: 1.65rem;
            bottom: 0.26rem;
            z-index: 2;
            text-align: center;
            line-height: 0.46rem;
            width: 1.52rem;
            height: 0.46rem;
            background-color: #1f1f1f;
            border-radius: 23px;
            font-family: SourceHanSansCN-Regular;
            font-size: 0.18rem;
            color: #ffffff;
          }
        }

        .right_content {
          display: none;
        }
      }
      .black,
      .red,
      .blue,
      .green,
      .origin {
        width: 30%;
        float: left;
      }
      .more {
        float: right;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .moreText {
          font-family: AlibabaPuHuiTiR;
          font-size: 0.28rem;
          font-weight: normal;
          font-stretch: normal;
          line-height: 0.3rem;
          letter-spacing: 0px;
          color: #666666;
        }
        .iconfont {
          font-size: 0.3rem;
          color: #cccccc;
          margin-left: 0.06rem;
        }
      }
      .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .bannerItem {
          width: 48%;
          height: 100%;
          img {
            width: 100%;
          }
        }
      }
      .listWrap {
        width: 100%;
        height: auto;
        // background-color: #ffffff;
        margin-top: 0;
        padding: 0 0.15rem;
        .list {
          widows: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          .item {
            border-radius: 0.05rem;
            height: 100%;
            width: 48%;
            overflow: hidden;
            margin-top: 0.13rem;
            clear: both;
            margin-right: 0;
            margin-left: 0;
          }
          .item:nth-child(2n) {
            margin-left: 0.277rem;
            margin-right: 0;
          }
          .caseImg {
            display: block;
            width: 100%;
            height: 2rem;
          }
          .caseInfo {
            width: 100%;
            height: auto;
            background: #fff;
            padding: 0.07rem;
          }
          .intro {
            width: 100%;
            height: 36px;
            margin-top: 0.17rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            margin-bottom: 0.1rem;
            font-size: 14px;
          }
          .tags {
          width: 100%;
          height: 21px;
          justify-content: flex-start;
          overflow: hidden;
          align-items: center;
          flex-wrap: wrap;
        }
        .tag {
          min-width: 25%;
          max-width: 30%;
          padding: 0 2px;
          line-height: 18px;
          font-family: SourceHanSansCN-Regular;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #ff9d4e;
          border: 0.01rem solid #ff9d4e;
          margin-right: 0.03rem;
          box-sizing: border-box;
          border-radius: 0.05rem;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .tag:nth-child(4) {
          margin-right: 0;
        }
        }
      }
    }
    // 楼层
    .indexContainer .floorBox {
      width: 101px;
      padding: 60px 0;
      background-color: #ffffff;
      border-radius: 50px;
      position: fixed;
      left: 2rem;
      top: 60%;
      transform: translateY(-50%);
      z-index: 20;
      .floorNav {
        width: 100%;
        height: 52px;
        background-color: #ffffff;
        font-family: AlibabaPuHuiTiR;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 52px;
        text-align: center;
        letter-spacing: 0px;
        color: #666666;
        cursor: pointer;
      }
      .floorNav:hover {
        background-color: #fe3846;
        color: #fff;
        transition: 0.1s linear;
      }
      .active {
        background-color: #fe3846;
        color: #fff;
        transition: 0.1s linear;
      }
    }
    .backTop {
      width: 87px;
      height: 246px;
      background-color: #ffffff;
      border-radius: 43px;
      border: solid 1px #d2d2d2;
      position: fixed;
      right: 200px;
      bottom: 200px;
      z-index: 99;
    }
    .main_index .floor .title {
      width: 100%;
      height: 0.5rem;
      font-family: SourceHanSansSC-Bold;
      font-size: 0.3rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #333333;
      background: #fff;
    }
    .main_index .titleWrap {
      width: 100%;
      padding: 0.22rem 0.15rem;
      margin-top: 0px;
      background: #fff;
      margin-top: 0.13rem;
    }
  }
}
</style>
